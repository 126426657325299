<template>
  <div class="h-auto min-h-[80vh]">
    <section
      v-if="getCartData.length > 0"
      class="block md:hidden fixed inset-x-0 bottom-0 z-50 bg-white shadow-lg"
    >
      <div v-if="paymentCode != 'paylater'" class="mx-4 mt-4 flex items-center">
        <div
          class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center border"
          :class="
            !isCouponExist && alertCoupon ? 'border-error' : 'border-[#EEEEEE]'
          "
        >
          <div class="p-2 z-10">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.625 9.99992C10.625 10.2524 10.4725 10.4805 10.2393 10.5774C10.0056 10.6743 9.73685 10.6205 9.5581 10.4418C9.37935 10.263 9.3256 9.99429 9.42247 9.76055C9.51935 9.52743 9.74747 9.37492 9.99998 9.37492C10.1656 9.37492 10.325 9.44054 10.4418 9.55804C10.5593 9.67491 10.625 9.83429 10.625 9.99991L10.625 9.99992ZM6.62498 5.99992C6.62498 5.74742 6.47248 5.5193 6.23935 5.42242C6.0056 5.32554 5.73685 5.37929 5.5581 5.55804C5.37935 5.73679 5.3256 6.00555 5.42247 6.23929C5.51935 6.47241 5.74747 6.62492 5.99998 6.62492C6.16561 6.62492 6.32497 6.5593 6.44185 6.4418C6.55935 6.32493 6.62497 6.16555 6.62497 5.99993L6.62498 5.99992ZM13.5 7.99992C13.5006 8.83493 14.0906 9.55304 14.9088 9.71672C15.255 9.78859 15.5025 10.0948 15.5 10.4486V11.9998C15.4994 12.6898 14.94 13.2492 14.25 13.2498H1.75002C1.06002 13.2492 0.500643 12.6898 0.500019 11.9998V10.4486C0.497519 10.0948 0.745011 9.78859 1.09127 9.71672C1.91127 9.55547 2.50251 8.8361 2.50251 7.99992C2.50251 7.16374 1.91127 6.44429 1.09127 6.28312C0.745011 6.21124 0.497523 5.90499 0.500019 5.55125V4C0.500644 3.31 1.06002 2.75062 1.75002 2.75H14.25C14.94 2.75062 15.4994 3.31 15.5 4V5.55125C15.5025 5.90499 15.255 6.21125 14.9088 6.28312C14.0906 6.44686 13.5006 7.16499 13.5 7.99992ZM6.00002 7.37493C6.55627 7.37493 7.05752 7.03992 7.27064 6.52618C7.48314 6.01243 7.36564 5.42118 6.97251 5.02742C6.57877 4.6343 5.98752 4.5168 5.47376 4.7293C4.96002 4.94242 4.62501 5.44366 4.62501 5.99992C4.62564 6.75867 5.24127 7.37429 6.00002 7.37493ZM6.01502 10.5149L10.515 6.01493C10.6619 5.86868 10.6619 5.63118 10.515 5.48493C10.3688 5.33805 10.1313 5.33805 9.98502 5.48493L5.48502 9.98493C5.33814 10.1312 5.33814 10.3687 5.48502 10.5149C5.63127 10.6618 5.86876 10.6618 6.01502 10.5149ZM11.375 9.99992C11.375 9.44366 11.04 8.94242 10.5263 8.7293C10.0125 8.5168 9.42127 8.6343 9.02751 9.02742C8.63439 9.42117 8.51689 10.0124 8.72939 10.5262C8.94251 11.0399 9.44376 11.3749 10 11.3749C10.7588 11.3743 11.3744 10.7587 11.375 9.99992Z"
                fill="#009DFF"
              />
            </svg>
          </div>
          <input
            type="text"
            name="coupon"
            placeholder=" "
            required
            v-model="coupon"
            class="py-2.5 block w-full pl-0 pr-2 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0"
          />
          <div v-if="isCouponExist" class="p-2 z-10">
            <svg
              class="w-5 h-5"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="8" fill="#2FA742" />
              <path
                d="M6.18374 11.9412C6.36688 12.129 6.62169 12.2355 6.888 12.2355C6.89059 12.2355 6.8933 12.2355 6.89614 12.2355C7.16555 12.2332 7.42178 12.1225 7.6035 11.9295L12.9241 6.27858C13.2848 5.89547 13.2568 5.30134 12.8619 4.95154C12.4668 4.602 11.8541 4.62893 11.4934 5.01191L6.87599 9.91578L5.43867 8.43984C5.07111 8.06261 4.4579 8.04583 4.06915 8.40189C3.68015 8.75807 3.66284 9.35246 4.03002 9.72968L6.18374 11.9412Z"
                fill="white"
              />
            </svg>
          </div>
          <label
            for="coupon"
            class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
            :class="
              !isCouponExist && alertCoupon
                ? 'text-[#DE1306]'
                : 'text-[#212121]'
            "
          >
            Kode Kupon
          </label>
        </div>
        <button
          v-if="paymentCode != 'paylater'"
          type="submit"
          @click="checkCoupon"
          class="font-solusi text-base font-semibold border rounded-full ml-2 px-4 py-2"
          :class="
            checkFormCoupon
              ? 'text-[#009DFF] bg-white border-[#009DFF]'
              : 'text-[#9E9E9E] border-[#9E9E9E] cursor-not-allowed'
          "
          :disabled="!checkFormCoupon"
        >
          Gunakan
        </button>
      </div>
      <p
        v-if="alertCoupon && paymentCode != 'paylater'"
        class="font-solusi text-2xs text-[#DE1306] px-4"
      >
        {{ alertCoupon }}
      </p>
      <p v-if="false" class="font-solusi text-[#212121] text-xs px-4 pt-2">
        Punya kode tambahan?
        <span
          @click="openModalReferral()"
          class="font-solusi text-[#0A72ED] text-xs hover:font-semibold cursor-pointer"
          >Gunakan disini</span
        >
      </p>
      <div class="grid grid-cols-2 gap-3 p-4">
        <div class="col-span-1 flex flex-col">
          <p class="font-solusi text-base text-[#212121] font-semibold">
            Total
          </p>
          <div class="flex items-center">
            <p
              class="font-solusi text-base font-semibold mr-2"
              :class="
                selectedItems.length > 0 ? 'text-[#009DFF]' : 'text-[#212121]'
              "
            >
              {{ selectedItems.length > 0 ? moneyFormat(total) : '-' }}
            </p>
            <svg
              v-if="selectedItems.length > 0"
              @click="showDetailPayment = true"
              class="w-4 h-4"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.99997 4.80082L2.2958 8.7631C2.01904 9.05888 1.54782 9.08071 1.24363 8.81159C0.939448 8.54247 0.917001 8.08426 1.19376 7.78847L5.44898 3.23698C5.74485 2.92101 6.25516 2.92101 6.55102 3.23698L10.8062 7.78847C11.083 8.08426 11.0606 8.54247 10.7564 8.81159C10.4522 9.0807 9.98096 9.05888 9.7042 8.7631L5.99997 4.80082Z"
                fill="#212121"
              />
            </svg>
          </div>
        </div>
        <button
          v-if="!displayPayment"
          @click="displayPayment = true"
          class="col-span-1 font-solusi text-base py-2 w-full font-semibold rounded-full border outline-none focus:outline-none"
          :class="
            selectedItems.length > 0
              ? 'text-white bg-[#00446F] border-[#00446F]'
              : 'text-[#9E9E9E] border-[#9E9E9E] cursor-not-allowed'
          "
          :disabled="selectedItems.length == 0"
        >
          Beli ({{ selectedItems.length }})
        </button>
        <button
          v-else
          @click="checkout()"
          class="col-span-1 font-solusi text-base py-2 w-full font-semibold rounded-full border outline-none focus:outline-none flex items-center justify-center"
          :class="
            !checkPayment || process
              ? 'text-[#9E9E9E] border-[#9E9E9E] cursor-not-allowed'
              : 'text-white bg-[#00446F] border-[#00446F]'
          "
          :disabled="!checkPayment || process"
        >
          <svg
            v-if="process"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="spinner"
            class="w-5 h-5 animate-spin mr-2"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="#9E9E9E"
              d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
            ></path>
          </svg>
          Bayar
        </button>
      </div>
    </section>
    <div
      class="mt-6 md:mt-10 mb-3 md:mb-5 max-w-6xl px-3 md:px-6 mx-auto flex items-center"
    >
      <svg
        v-if="displayPayment"
        @click="displayPayment = false"
        class="w-5 h-5 cursor-pointer mr-2"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.07942 12.3116C2.08342 12.3226 2.08342 12.3336 2.08842 12.3446C2.08842 12.3466 2.08842 12.3476 2.08942 12.3496C2.14942 12.4916 2.23742 12.6116 2.33942 12.7196C2.35042 12.7316 2.35442 12.7466 2.36542 12.7586L10.0134 20.3366C10.2624 20.5826 10.5884 20.7056 10.9144 20.7056C11.2404 20.7056 11.5654 20.5826 11.8144 20.3366C12.3124 19.8436 12.3124 19.0456 11.8144 18.5526L6.34042 13.1276L21.1054 13.1276C21.8094 13.1276 22.3784 12.5636 22.3784 11.8656C22.3784 11.1696 21.8094 10.6046 21.1054 10.6046L6.34142 10.6046L11.8154 5.15257C12.3134 4.66057 12.3134 3.86157 11.8154 3.36957C11.3184 2.87657 10.5114 2.87657 10.0134 3.36957L2.36542 10.9736C2.25542 11.0826 2.17642 11.2116 2.11442 11.3476C2.09442 11.3896 2.08342 11.4306 2.06942 11.4726C2.03542 11.5746 2.01442 11.6766 2.00742 11.7846C2.00442 11.8286 1.99842 11.8696 2.00042 11.9136C2.00542 12.0496 2.02942 12.1816 2.07942 12.3116Z"
          fill="#212121"
        />
      </svg>
      <p class="font-desain text-2xl font-semibold text-[#212121]">
        {{ displayPayment ? 'Pilih Metode Pembayaran' : 'Keranjang' }}
      </p>
    </div>
    <div
      v-if="getCartData.length == 0"
      class="max-w-6xl px-3 mx-auto my-20 w-full flex flex-col justify-center items-center"
    >
      <img src="/assets/images/modal/cart-empty.png" class="w-72" alt="" />
      <p class="font-solusi font-semibold text-lg text-[#00446F]">
        Ayo Beli Kelas
      </p>
    </div>
    <div
      v-else
      class="max-w-6xl px-3 md:px-6 mx-auto w-full flex flex-col md:flex-row"
    >
      <transition name="route" mode="out-in">
        <div v-if="!displayPayment" class="w-full md:w-[60%]">
          <div class="w-full pr-0 md:pr-6 mb-4">
            <div class="bg-white rounded-md shadow flex justify-between p-3">
              <label for="all" class="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  id="all"
                  name="all"
                  v-model="checkAll"
                  @click="toggleSelectAll"
                />
                <p class="font-solusi text-sm text-[#383838] ml-2">
                  Pilih Semua
                  <span class="font-solusi text-xs text-[#757575]"
                    >({{ selectedItems.length }} yang dipilih)</span
                  >
                </p>
              </label>
            </div>
          </div>
          <div
            v-for="cart in getCartData"
            :key="cart.code"
            class="w-full pr-0 md:pr-6 mb-4"
          >
            <div
              class="bg-white rounded-md shadow flex flex-col justify-between p-3"
            >
              <div class="grid grid-cols-4 gap-2 w-full">
                <div class="col-span-3 flex items-start">
                  <input
                    type="checkbox"
                    v-bind:value="cart"
                    v-model="selectedItems"
                    class="mt-1"
                    @change="updateCheckall()"
                  />
                  <div class="ml-3 flex items-center">
                    <div class="flex-grow flex items-start">
                      <img
                        :src="cart.thumbnail"
                        alt=""
                        class="object-cover rounded-md h-16 w-16"
                      />
                      <div class="ml-3 flex-grow items-start">
                        <div
                          v-tooltip="{
                            content: cart.name,
                          }"
                          class="font-solusi text-base text-[#383838] line-clamp-2 font-semibold overflow-hidden"
                        >
                          {{ cart.name }}
                        </div>
                        <p
                          v-if="cart.mentor_data"
                          class="font-solusi text-xs text-[#757575] font-medium"
                        >
                          Oleh {{ cart.mentor_data[0].name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-span-1">
                  <p
                    class="font-solusi text-base text-[#383838] text-right font-semibold"
                  >
                    Rp. {{ cart.price_formatted }}
                  </p>
                  <p
                    class="font-solusi text-xs text-[#DE1306] text-right font-medium line-through"
                  >
                    Rp. {{ cart.normal_price_formatted }}
                  </p>
                </div>
              </div>
              <div class="flex justify-end">
                <svg
                  @click="openDeleteCartItem(cart)"
                  class="w-5 h-5 text-[#333333] hover:text-[#DE1306] cursor-pointer"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3337 4C14.3337 4.34189 14.0763 4.62367 13.7447 4.66218L13.667 4.66667H13.1038L12.2829 13.0128C12.1906 13.9513 11.4014 14.6667 10.4584 14.6667H5.54228C4.59926 14.6667 3.81006 13.9513 3.71775 13.0128L2.89682 4.66667H2.33366C1.96547 4.66667 1.66699 4.36819 1.66699 4C1.66699 3.63181 1.96547 3.33333 2.33366 3.33333H5.66699C5.66699 2.04467 6.71166 1 8.00033 1C9.28899 1 10.3337 2.04467 10.3337 3.33333H13.667C14.0352 3.33333 14.3337 3.63181 14.3337 4ZM9.50033 6.16667C9.24719 6.16667 9.038 6.35477 9.00489 6.59882L9.00033 6.66667V11.3333L9.00489 11.4012C9.038 11.6452 9.24719 11.8333 9.50033 11.8333C9.75346 11.8333 9.96265 11.6452 9.99576 11.4012L10.0003 11.3333V6.66667L9.99576 6.59882C9.96265 6.35477 9.75346 6.16667 9.50033 6.16667ZM6.50033 6.16667C6.24719 6.16667 6.038 6.35477 6.00489 6.59882L6.00033 6.66667V11.3333L6.00489 11.4012C6.038 11.6452 6.24719 11.8333 6.50033 11.8333C6.75346 11.8333 6.96265 11.6452 6.99576 11.4012L7.00033 11.3333V6.66667L6.99576 6.59882C6.96265 6.35477 6.75346 6.16667 6.50033 6.16667ZM8.00033 2.33333C7.44804 2.33333 7.00033 2.78105 7.00033 3.33333H9.00033C9.00033 2.78105 8.55261 2.33333 8.00033 2.33333Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="w-full md:w-[60%]">
          <div class="w-full pr-0 md:pr-6 mb-4">
            <div v-if="listPaymentEwallet.length > 0">
              <div
                class="bg-white rounded-md shadow flex flex-col justify-between p-3 mb-4"
              >
                <p class="font-desain text-lg text-[#212121] font-semibold">
                  E-Wallet
                </p>
                <p class="font-solusi text-base text-[#212121] mb-2">
                  Kamu bisa melakukan transaksi melalui e-wallet yang kamu
                  miliki.
                </p>
                <div class="grid grid-cols-1 md:grid-cols-4 gap-3">
                  <div
                    v-for="(payment, i) in listPaymentEwallet"
                    :key="i"
                    @click="selectPayment(payment)"
                    class="cursor-pointer col-span-1 rounded-md border relative p-2 h-16 w-full md:h-[130px] md:w-[130px] flex flex-row items-center justify-between md:justify-center"
                    :class="
                      paymentCode == payment.code
                        ? 'bg-[#FFFAF1] border-[#FFC200]'
                        : 'bg-white border-[#EEEEEE]'
                    "
                  >
                    <svg
                      v-if="paymentCode == payment.code"
                      class="absolute -top-2.5 -right-2.5 w-6 h-6"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#009DFF" />
                      <path
                        d="M9.27366 17.9117C9.54836 18.1935 9.93059 18.3532 10.3301 18.3532C10.3339 18.3532 10.338 18.3532 10.3423 18.3532C10.7464 18.3498 11.1307 18.1838 11.4033 17.8943L19.3842 9.41787C19.9253 8.84321 19.8833 7.95201 19.2909 7.42732C18.6983 6.903 17.7792 6.94339 17.2381 7.51786L10.312 14.8737L8.15605 12.6598C7.60471 12.0939 6.68489 12.0687 6.10178 12.6028C5.51827 13.1371 5.49231 14.0287 6.04308 14.5945L9.27366 17.9117Z"
                        fill="white"
                      />
                    </svg>
                    <div class="flex flex-row md:flex-col items-center">
                      <img
                        :src="payment.icon"
                        class="md:mx-auto h-14 md:h-20"
                        alt=""
                      />
                      <p
                        class="ml-3 md:ml-0 font-solusi text-sm text-[#212121] text-center"
                      >
                        {{ payment.name }}
                      </p>
                    </div>
                    <input
                      type="radio"
                      v-model="paymentCode"
                      :value="payment.code"
                      class="block md:hidden"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="listPaymentBank.length > 0">
              <div
                class="bg-white rounded-md shadow flex flex-col justify-between p-3 mb-4"
              >
                <p class="font-desain text-lg text-[#212121] font-semibold">
                  Transfer Bank
                </p>
                <p class="font-solusi text-base text-[#212121] mb-2">
                  Kode unik transaksi untuk mempercepat proses verifikasi.
                </p>
                <div class="grid grid-cols-1 md:grid-cols-4 gap-3">
                  <div
                    v-for="(payment, i) in listPaymentBank"
                    :key="i"
                    @click="selectPayment(payment)"
                    class="cursor-pointer col-span-1 rounded-md border relative p-2 h-16 w-full md:h-[130px] md:w-[130px] flex flex-row items-center justify-between md:justify-center"
                    :class="
                      paymentCode == payment.code
                        ? 'bg-[#FFFAF1] border-[#FFC200]'
                        : 'bg-white border-[#EEEEEE]'
                    "
                  >
                    <svg
                      v-if="paymentCode == payment.code"
                      class="absolute -top-2.5 -right-2.5 w-6 h-6"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#009DFF" />
                      <path
                        d="M9.27366 17.9117C9.54836 18.1935 9.93059 18.3532 10.3301 18.3532C10.3339 18.3532 10.338 18.3532 10.3423 18.3532C10.7464 18.3498 11.1307 18.1838 11.4033 17.8943L19.3842 9.41787C19.9253 8.84321 19.8833 7.95201 19.2909 7.42732C18.6983 6.903 17.7792 6.94339 17.2381 7.51786L10.312 14.8737L8.15605 12.6598C7.60471 12.0939 6.68489 12.0687 6.10178 12.6028C5.51827 13.1371 5.49231 14.0287 6.04308 14.5945L9.27366 17.9117Z"
                        fill="white"
                      />
                    </svg>
                    <div class="flex flex-row md:flex-col items-center">
                      <img
                        :src="payment.icon"
                        class="md:mx-auto h-14 md:h-20"
                        alt=""
                      />
                      <p
                        class="ml-3 md:ml-0 font-solusi text-sm text-[#212121] text-center"
                      >
                        {{ payment.name }}
                      </p>
                    </div>
                    <input
                      type="radio"
                      v-model="paymentCode"
                      :value="payment.code"
                      class="block md:hidden"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="bg-white rounded-md shadow flex flex-col justify-between p-3 mb-4"
            >
              <p class="font-desain text-lg text-[#212121] font-semibold">
                Paylater
              </p>
              <p class="font-solusi text-base text-[#212121] mb-2">
                Pesan sekarang bayar nanti di setiap akhir bulan.
              </p>
              <div class="grid grid-cols-1 md:grid-cols-4 gap-3">
                <div
                  @click="selectPayment(paylater)"
                  class="cursor-pointer col-span-1 rounded-md border relative p-2 h-16 w-full md:h-[130px] md:w-[130px] flex flex-row items-center justify-between md:justify-center"
                  :class="
                    paymentCode == paylater.code
                      ? 'bg-[#FFFAF1] border-[#FFC200]'
                      : 'bg-white border-[#EEEEEE]'
                  "
                >
                  <svg
                    v-if="paymentCode == paylater.code"
                    class="absolute -top-2.5 -right-2.5 w-6 h-6"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#009DFF" />
                    <path
                      d="M9.27366 17.9117C9.54836 18.1935 9.93059 18.3532 10.3301 18.3532C10.3339 18.3532 10.338 18.3532 10.3423 18.3532C10.7464 18.3498 11.1307 18.1838 11.4033 17.8943L19.3842 9.41787C19.9253 8.84321 19.8833 7.95201 19.2909 7.42732C18.6983 6.903 17.7792 6.94339 17.2381 7.51786L10.312 14.8737L8.15605 12.6598C7.60471 12.0939 6.68489 12.0687 6.10178 12.6028C5.51827 13.1371 5.49231 14.0287 6.04308 14.5945L9.27366 17.9117Z"
                      fill="white"
                    />
                  </svg>
                  <div class="flex flex-row md:flex-col items-center">
                    <img
                      :src="paylater.icon"
                      class="md:mx-auto h-5 md:h-7 md:mb-5"
                      alt=""
                    />
                    <p
                      class="ml-3 md:ml-0 font-solusi text-sm text-[#212121] text-center"
                    >
                      {{ paylater.name }}
                    </p>
                  </div>
                  <input
                    type="radio"
                    v-model="paylaterCode"
                    :value="paylater.code"
                    class="block md:hidden"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div
        class="w-full hidden md:block md:w-[40%] bg-white rounded-md sticky top-20 self-start shadow"
      >
        <form
          v-if="paymentCode != 'paylater'"
          @submit.prevent="checkCoupon"
          class="flex items-start justify-between px-4 pt-4 pb-3"
        >
          <span class="font-solusi text-base text-[#212121] font-semibold mt-2"
            >Kode Kupon</span
          >
          <div class="flex flex-col">
            <div class="relative">
              <input
                type="text"
                v-model="coupon"
                class="font-solusi text-[#212121] rounded-sm border w-52 p-2 text-xs outline-none focus:outline-none"
                :class="[
                  useCoupon ? '' : 'border-[#EEEEEE]',
                  isCouponExist ? 'border-[#EEEEEE] pr-7' : '',
                  !isCouponExist && alertCoupon
                    ? 'border-[#DE1306] bg-[#FFF4F3]'
                    : '',
                ]"
                placeholder="Masukkan kode kupon disini"
              />
              <svg
                v-if="isCouponExist"
                class="absolute top-2 right-2"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="8" cy="8" r="8" fill="#2FA742" />
                <path
                  d="M6.18374 11.9412C6.36688 12.129 6.62169 12.2355 6.888 12.2355C6.89059 12.2355 6.8933 12.2355 6.89614 12.2355C7.16555 12.2332 7.42178 12.1225 7.6035 11.9295L12.9241 6.27858C13.2848 5.89547 13.2568 5.30134 12.8619 4.95154C12.4668 4.602 11.8541 4.62893 11.4934 5.01191L6.87599 9.91578L5.43867 8.43984C5.07111 8.06261 4.4579 8.04583 4.06915 8.40189C3.68015 8.75807 3.66284 9.35246 4.03002 9.72968L6.18374 11.9412Z"
                  fill="white"
                />
              </svg>
            </div>
            <p v-if="alertCoupon" class="font-solusi text-2xs text-[#DE1306]">
              {{ alertCoupon }}
            </p>
          </div>
          <button
            type="submit"
            @click="checkCoupon"
            class="font-solusi text-xs font-semibold border rounded-full px-4 py-2 "
            :class="
              checkFormCoupon
                ? 'text-[#009DFF] bg-white border-[#009DFF]'
                : 'text-[#9E9E9E] border-[#9E9E9E] cursor-not-allowed'
            "
            :disabled="!checkFormCoupon"
          >
            Gunakan
          </button>
        </form>
        <p v-if="false" class="font-solusi text-[#212121] text-xs px-4 pb-4">
          Punya kode tambahan?
          <span
            @click="openModalReferral()"
            class="font-solusi text-[#0A72ED] text-xs hover:font-semibold cursor-pointer"
            >Gunakan disini</span
          >
        </p>
        <div
          v-if="paymentCode && paymentCode != 'paylater'"
          class="w-full border-t-4 border-[#F5F6F8]"
        ></div>
        <div v-if="paymentCode" class="p-4">
          <p class="font-solusi text-sm text-[#212121] font-semibold mb-2">
            Pembayaran
          </p>
          <div
            class="flex items-center rounded-md bg-[#F5F7FD] px-6"
            :class="selectedPayment.code == 'paylater' ? 'py-4' : 'py-0'"
          >
            <img
              v-if="selectedPayment.code == 'paylater'"
              :src="selectedPayment.icon"
              class="h-6"
              alt=""
            />
            <img v-else :src="selectedPayment.icon" class="h-20" alt="" />
            <span class="ml-3 font-solusi text-sm text-[#212121]">{{
              selectedPayment.name
            }}</span>
          </div>
        </div>
        <div class="w-full border-t-4 border-[#F5F6F8]"></div>
        <div class="p-4 flex flex-col">
          <p class="font-solusi text-base text-[#212121] font-semibold mb-4">
            Ringkasan Pembelian
          </p>
          <div v-if="selectedItems.length > 0">
            <div
              v-for="item in selectedItems"
              :key="item.code"
              class="grid grid-cols-12 mb-2"
            >
              <span class="col-span-9 font-solusi text-sm text-[#757575]">
                {{ item.name }}
              </span>
              <span
                class="col-span-3 font-solusi text-sm text-[#212121] text-right"
              >
                Rp. {{ item.normal_price_formatted }}
              </span>
            </div>
            <div class="border-t border-gray-300 border-dashed"></div>
            <div class="flex flex-row justify-between my-2">
              <span class="font-solusi text-sm text-[#212121] font-semibold">
                Sub Total
              </span>
              <span class="font-solusi text-sm text-[#212121] font-semibold">
                {{ moneyFormat(subtotalNormal) }}
              </span>
            </div>
            <div class="flex flex-row justify-between mb-2">
              <span class="font-solusi text-sm text-success font-semibold">
                Diskon Promo
              </span>
              <span class="font-solusi text-sm text-success font-semibold">
                ({{ moneyFormat(subtotalNormal - subtotal) }})
              </span>
            </div>
            <div v-if="discount > 0" class="flex flex-row justify-between mb-2">
              <span class="font-solusi text-sm text-success font-semibold">
                {{ discountWord }}
              </span>
              <span class="font-solusi text-sm text-success font-semibold">
                ({{ moneyFormat(discount) }})
              </span>
            </div>
            <div class="border-t border-gray-200 w-full"></div>
            <div class="flex flex-row justify-between mt-2 mb-5">
              <span class="font-solusi font-black text-lg text-[#212121]"
                >Total</span
              >
              <span class="font-solusi font-black text-lg text-[#212121]">{{
                moneyFormat(total)
              }}</span>
            </div>
          </div>
          <div v-else>
            <p
              class="font-solusi text-sm text-[#757575] text-center font-semibold mb-5"
            >
              Belum ada kelas yang dipilih
            </p>
          </div>
          <button
            v-if="!displayPayment"
            @click="displayPayment = true"
            class="font-solusi text-base py-2 w-full font-semibold rounded-full border"
            :class="
              selectedItems.length > 0
                ? 'text-white bg-[#00446F] border-[#00446F]'
                : 'text-[#9E9E9E] border-[#9E9E9E] cursor-not-allowed'
            "
            :disabled="selectedItems.length == 0"
          >
            Pilih Metode Bayar
          </button>
          <button
            v-else
            @click="checkout()"
            class="font-solusi text-base py-2 w-full font-semibold rounded-full border flex items-center justify-center"
            :class="
              !checkPayment || process
                ? 'text-[#9E9E9E] border-[#9E9E9E] cursor-not-allowed'
                : 'text-white bg-[#00446F] border-[#00446F]'
            "
            :disabled="!checkPayment || process"
          >
            <svg
              v-if="process"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="spinner"
              class="w-5 h-5 animate-spin mr-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#9E9E9E"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
              ></path>
            </svg>
            Bayar
          </button>
        </div>
      </div>
    </div>
    <ModalDelete
      title="Hapus Pesanan"
      :item="'Kelas ' + selectedItem?.name + ' dari Keranjang'"
      :showDelete="showModalDelete"
      :closeDelete="closeDeleteCartItem"
      :submitDelete="deleteCartItem"
      :loadingDelete="loadingDelete"
    />

    <!-- Popup Referral -->
    <transition name="fade">
      <div
        v-if="showModalReferral"
        class="opacity-50 fixed inset-0 z-50 bg-black w-full h-full"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showModalReferral"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <div
            v-click-away="closeModalRefferal"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <div class="flex items-start justify-end px-3 pt-3 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalRefferal()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
            <div
              class="pb-6 px-8 md:px-11 flex-auto overflow-x-hiden overflow-y-auto"
            >
              <img
                src="/assets/images/modal/voucher.svg"
                alt=""
                class="w-30 md:w-36 m-auto"
              />
              <p
                class="font-desain text-[#212121] text-lg text-center my-2 font-semibold"
              >
                Gunakan kode promo tambahan
              </p>
              <p class="font-solusi text-[#212121] text-sm text-center mb-4">
                Kode promo tambahan merupakan kode referral dari temanmu, kode
                promo dari media sosial, atau kode dari Mentor.
              </p>
              <form
                @submit.prevent="checkCoupon"
                class="flex items-start justify-between px-4"
              >
                <div class="flex flex-col">
                  <div class="relative">
                    <input
                      type="text"
                      v-model="referralCode"
                      class="font-solusi text-[#212121] rounded-sm border w-52 p-2 text-xs outline-none focus:outline-none border-[#EEEEEE] pr-7"
                      placeholder="Masukkan kode referral"
                    />
                    <svg
                      class="absolute top-2 right-2"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#2FA742" />
                      <path
                        d="M6.18374 11.9412C6.36688 12.129 6.62169 12.2355 6.888 12.2355C6.89059 12.2355 6.8933 12.2355 6.89614 12.2355C7.16555 12.2332 7.42178 12.1225 7.6035 11.9295L12.9241 6.27858C13.2848 5.89547 13.2568 5.30134 12.8619 4.95154C12.4668 4.602 11.8541 4.62893 11.4934 5.01191L6.87599 9.91578L5.43867 8.43984C5.07111 8.06261 4.4579 8.04583 4.06915 8.40189C3.68015 8.75807 3.66284 9.35246 4.03002 9.72968L6.18374 11.9412Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <p class="font-solusi text-2xs text-[#DE1306]">
                    kode yang dimasukkan salah
                  </p>
                </div>
                <button
                  type="submit"
                  @click="checkCoupon"
                  class="font-solusi text-xs font-semibold border rounded-full px-4 py-2 "
                  :class="
                    checkFormCoupon
                      ? 'text-[#009DFF] bg-white border-[#009DFF]'
                      : 'text-[#9E9E9E] border-[#9E9E9E] cursor-not-allowed'
                  "
                  :disabled="!checkFormCoupon"
                >
                  Gunakan
                </button>
              </form>
              <div class="flex items-center bg-[#FFFAF1] rounded-md my-2 p-2">
                <svg
                  class="w-10 h-10"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.27281 1.85461C7.96599 1.47067 8.83134 1.69377 9.25656 2.34811L9.31176 2.43999L14.4844 11.7788C14.6076 12.0013 14.6722 12.2514 14.6722 12.5056C14.6722 13.2995 14.0555 13.9494 13.2749 14.0022L13.1722 14.0056H2.8284C2.57419 14.0056 2.32416 13.941 2.10177 13.8179C1.40854 13.434 1.13851 12.5821 1.46755 11.8745L1.51615 11.779L6.68735 2.44015C6.8237 2.1939 7.02658 1.99099 7.27281 1.85461ZM8.00078 10.6677C7.63308 10.6677 7.335 10.9658 7.335 11.3335C7.335 11.7012 7.63308 11.9992 8.00078 11.9992C8.36848 11.9992 8.66655 11.7012 8.66655 11.3335C8.66655 10.9658 8.36848 10.6677 8.00078 10.6677ZM7.99934 5.33188C7.65745 5.332 7.37576 5.58946 7.33737 5.92104L7.33291 5.99878L7.33411 9.3327L7.33863 9.41045C7.37726 9.74199 7.65913 9.99925 8.00102 9.99913C8.34291 9.99901 8.6246 9.74155 8.66299 9.40997L8.66745 9.33222L8.66625 5.9983L8.66173 5.92056C8.6231 5.58901 8.34123 5.33175 7.99934 5.33188Z"
                    fill="#F3DB00"
                  />
                </svg>
                <p class="ml-2 font-solusi text-xs text-[#212121]">
                  Kamu peserta Kartu Prakerja? Gunakan kode referral bersamaan
                  dengan kode kupon dari pembelian kelas.
                  <span class="font-solusi text-xs font-semibold text-[#0A72ED]"
                    >Pelajari lebih lanjut</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Popup Detail Payment -->
    <transition name="fade">
      <div
        v-if="showDetailPayment"
        class="opacity-50 fixed inset-0 z-50 bg-black w-full h-full"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showDetailPayment"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/3 my-auto md:my-6 mx-2 md:mx-auto">
          <div
            v-click-away="closeDetailPayment"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none divide-y divide-gray-200 max-h-[65vh]"
          >
            <div class="flex items-center justify-between px-5 py-3 rounded-t">
              <p class="font-desain text-lg text-[#212121] font-semibold">
                Ringkasan Pembelian
              </p>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="showDetailPayment = false"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
            <div class="overflow-y-auto scroll-custom px-6 py-4">
              <div
                v-for="item in selectedItems"
                :key="item.code"
                class="grid grid-cols-12 mb-2"
              >
                <span class="col-span-9 font-solusi text-sm text-[#757575]">
                  {{ item.name }}
                </span>
                <span
                  class="col-span-3 font-solusi text-sm text-[#212121] text-right"
                >
                  Rp. {{ item.normal_price_formatted }}
                </span>
              </div>
              <div class="border-t border-gray-300 border-dashed"></div>
              <div class="flex flex-row justify-between my-2">
                <span class="font-solusi text-sm text-[#212121] font-semibold">
                  Sub Total
                </span>
                <span class="font-solusi text-sm text-[#212121] font-semibold">
                  {{ moneyFormat(subtotalNormal) }}
                </span>
              </div>
              <div class="flex flex-row justify-between mb-2">
                <span class="font-solusi text-sm text-success font-semibold">
                  Discount
                </span>
                <span class="font-solusi text-sm text-success font-semibold">
                  ({{ moneyFormat(subtotalNormal - subtotal) }})
                </span>
              </div>
              <div
                v-if="discount > 0"
                class="flex flex-row justify-between mb-2"
              >
                <span class="font-solusi text-sm text-success font-semibold">
                  {{ discountWord }}
                </span>
                <span class="font-solusi text-sm text-success font-semibold">
                  ({{ moneyFormat(discount) }})
                </span>
              </div>
              <div class="border-t border-gray-200 w-full"></div>
              <div class="flex flex-row justify-between mt-2 mb-5">
                <span class="font-solusi font-black text text-[#212121]"
                  >Total</span
                >
                <span class="font-solusi font-black text text-[#212121]">{{
                  moneyFormat(total)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <Loading :show="showLoading" />
  </div>
</template>

<script lang="ts">
import { Cart } from '@/dto/Cart'
import { CouponRequest } from '@/dto/Coupon'
import { CartModel, CouponModel } from '@/models'
import TransactionModel from '@/models/TransactionModel'
import { defineComponent } from 'vue'
import { moneyFormat } from '@/utils/Util'
import { Payment } from '@/dto'
import ModalDelete from '@/main/components/Modals/ModalDelete.vue'
import ClassroomModel from '@/models/ClassroomModel'
import Loading from '@/main/components/Loading.vue'

export default defineComponent({
  name: 'Cart.List',
  components: {
    ModalDelete,
    Loading,
  },
  data() {
    return {
      checkAll: false,
      code: '' as string,
      items: [] as Cart[],
      subtotal: 0,
      subtotalNormal: 0,
      total: 0,
      discount: 0,
      discountWord: '' as string,
      selectedItems: [] as Cart[],
      coupon: '' as string,
      process: false,
      paymentCode: '' as string,
      listPaymentManual: [] as Payment[],
      listPaymentBank: [] as any,
      listPaymentEwallet: [] as any,
      listPaymentManualQRIS: [] as any,
      listPaymentRetail: [] as any,
      paylater: {
        account: 'PAYLATER',
        code: 'paylater',
        icon: '/assets/images/payment/paylater.png',
        id: 21,
        instructions: '',
        name: 'Sekolah Desain PAYLATER',
      },
      displayModalPaylater: false,
      displayPayment: false,
      showModalDelete: false,
      selectedItem: {} as any,
      useCoupon: false,
      isCouponExist: false,
      alertCoupon: '' as string,
      selectedPayment: {} as any,
      showDetailPayment: false,
      showModalReferral: false,
      referralCode: '' as string,
      buyNow: this.$route.query.next as string,
      course: this.$route.query.course as string,
      add: this.$route.query.add as string,
      showLoading: false as boolean,
      loadingDelete: false as boolean,
    }
  },
  computed: {
    // getters: profile loading & data
    getProfileDataLoading(): boolean {
      return this.$store.getters['profileStore/getProfileDataLoading']
    },
    getProfileData(): any {
      return this.$store.getters['profileStore/getProfileData']
    },
    getCartData(): Array<Cart> {
      return this.$store.getters['cartStore/getCartData']
    },
    checkFormCoupon(): boolean {
      if (this.coupon) {
        return true
      } else {
        return false
      }
    },
    checkPayment(): boolean {
      if (this.paymentCode) {
        return true
      } else {
        return false
      }
    },
  },
  async mounted() {
    // await this.$store.dispatch('profile/Store/fetchProfileData')
    this.showLoading = true
    await this.getPaymentMethod()
    this.listPaymentManual = [
      {
        type: 'E-Wallet',
        note:
          'Kamu bisa melakukan transaksi melalui e-wallet yang kamu miliki.',
        payment: [
          {
            account: 'OVO',
            code: 'ovo',
            icon: '/assets/images/payment/logo_ovo.png',
            id: 22,
            instructions: '',
            name: 'OVO',
          },
          {
            account: 'Dana',
            code: 'dana',
            icon: '/assets/images/payment/logo_dana.png',
            id: 23,
            instructions: '',
            name: 'DANA',
          },
          {
            account: 'Gopay',
            code: 'gopay',
            icon: '/assets/images/payment/logo_gopay.png',
            id: 24,
            instructions: '',
            name: 'GOPAY',
          },
          {
            account: 'Link Aja',
            code: 'link-aja',
            icon: '/assets/images/payment/logo_linkaja.png',
            id: 25,
            instructions: '',
            name: 'Link Aja',
          },
          {
            account: 'Shopee Pay',
            code: 'shopeepay',
            icon: '/assets/images/payment/logo_shopeepay.png',
            id: 26,
            instructions: '',
            name: 'Shopee Pay',
          },
        ],
      },
      {
        type: 'Transfer Bank',
        note: 'Kode unik transaksi untuk mempercepat proses verifikasi.',
        payment: [
          {
            account: '1380556667777',
            code: 'mandiritf',
            icon: '/assets/images/payment/logo_mandiri.png',
            id: 19,
            instructions:
              '1. Masukkan kartu ke mesin ATM, pilihlah bahasa masukan kemudian masukkan kode PIN ATM Anda dengan benar.\\n2. Pilihlah menu “Transaksi Lainnya” pada laman menu.\\n3. Pilihlah “Transfer” , lalu pilih “Rekening Mandiri”.\\n4. Masukan Nomor $1 a.n. $3 kemudian pilih Benar.\\n5. Masukan jumlah nominal uang $2 kemudian pilih Benar.\\n6. Mohon periksa kembali informasi nomor rekening tujuan dan nominal uang yang akan ditransfer.\\n7. Kemudian, selembar struk akan keluar dari ATM. Jangan lupa untuk mengunggah bukti struk transaksi ini untuk menyelesaikan transaksi.',
            name: 'Bank Mandiri',
          },
          {
            account: '3948000999',
            code: 'bcatf',
            icon: '/assets/images/payment/logo_bca.png',
            id: 20,
            instructions:
              '1. Masukkan kartu ke mesin ATM, pilihlah bahasa masukan kemudian masukkan kode PIN ATM Anda dengan benar.\\n2. Pilihlah menu “Transfer” dan “Ke Rek BCA”.\\n3. Kemudian, masukkan nomor rekening BCA yang dituju $1 a.n. $3\\n4. Setelah itu, lanjutkan dengan memasukkan nominal sebesar $2.\\n5. Lalu, ada pertanyaan tentang No Referensi. Bagian ini bisa dikosongkan.\\n6. Tekan OK atau Ya.\\n7. Kemudian, selembar struk akan keluar dari ATM. Jangan lupa untuk mengunggah bukti struk transaksi ini untuk menyelesaikan transaksi.',
            name: 'Bank BCA',
          },
        ],
      },
      {
        type: 'Paylater',
        note: 'Pesan sekarang bayar nanti di setiap akhir bulan.',
        payment: [
          {
            account: 'PAYLATER',
            code: 'paylater',
            icon: '/assets/images/payment/paylater.png',
            id: 21,
            instructions: '',
            name: 'Sekolah Desain PAYLATER',
          },
        ],
      },
    ] as any

    if (this.add) {
      const split = this.add.split(',')
      for (let index = 0; index < split.length; index++) {
        await CartModel.addSingle(split[index])
      }

      await this.$store.dispatch('cartStore/fetchCart')
    } else {
      if (Object.keys(this.getCartData).length == 0) {
        await this.$store.dispatch('cartStore/fetchCart')
      }
    }
    this.showLoading = false

    if (this.buyNow == 'buy-now') {
      if (this.course) {
        const course: any = this.getCartData.filter(
          (el: Cart) => el.code == this.course
        )
        if (course) {
          this.selectedItems.push(course[0])
          this.updateSubtotal()
          this.displayPayment = true
        }
      } else {
        if (this.getCartData.length > 0) {
          this.toggleSelectAll()
          this.displayPayment = true
        }
      }
    }
  },
  methods: {
    async getPaymentMethod() {
      const { data } = await TransactionModel.listPayment()
      this.listPaymentBank = data.filter(
        e =>
          e.group_code === 'manual' &&
          e.code != 'ovo' &&
          e.code != 'dana' &&
          e.code != 'gopay' &&
          e.code != 'link-aja' &&
          e.code != 'shopeepay' &&
          e.code != 'qris-manual'
      )
      this.listPaymentEwallet = data.filter(
        e =>
          e.group_code === 'manual' &&
          (e.code == 'ovo' ||
            e.code == 'dana' ||
            e.code == 'gopay' ||
            e.code == 'link-aja' ||
            e.code == 'shopeepay')
      )
    },
    async getCart() {
      await this.$store.dispatch('cartStore/fetchCart')
    },
    updateCheckall() {
      if (this.selectedItems.length == this.getCartData.length) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
      this.discount = 0
      this.discountWord = ''
      this.useCoupon = false
      this.isCouponExist = false
      this.alertCoupon = ''
      if (this.coupon) {
        this.checkCoupon()
      }
      this.updateSubtotal()
    },
    toggleSelectAll() {
      this.checkAll = !this.checkAll
      this.selectedItems = [] as Cart[]
      if (this.checkAll) {
        if (this.getCartData.length > 0) {
          this.getCartData.forEach((el: any) => {
            this.selectedItems.push(el)
          })
        }
        if (this.coupon) {
          this.checkCoupon()
        }
        this.updateSubtotal()
      }
    },
    getClassroomByCode(code: string) {
      for (let i = 0; i < this.items.length; i++) {
        const element = this.items[i]
        if (code == element.code) {
          return element
        }
      }
    },
    updateSubtotal() {
      this.subtotal = 0
      this.subtotalNormal = 0
      this.selectedItems.forEach(el => {
        this.subtotal += el.price
        this.subtotalNormal += el.normal_price
      })
      this.total = this.subtotal - this.discount
    },
    removeFromSelected(code: string) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        const element = this.selectedItems[i]
        if (element.code == code) {
          this.selectedItems.splice(i, 1)
        }
      }
      this.updateSubtotal()
      if (this.selectedItems.length > 0) {
        if (this.coupon != ``) {
          this.checkCoupon()
        }
        return
      }

      this.discount = 0
      this.discountWord = ``
      this.coupon = ``
    },
    async checkCoupon() {
      this.useCoupon = true
      this.isCouponExist = false
      this.alertCoupon = ''
      if (this.coupon == '') {
        this.useCoupon = true
        this.isCouponExist = false
        this.discount = 0
        this.discountWord = ''
        this.updateSubtotal()
        return
      }

      if (this.selectedItems.length == 0) {
        this.alertCoupon = 'Pilih pesanan dulu sebelum pakai kupon'
        return
      }

      const classrooms = [
        {
          code: '' as string,
        },
      ]

      this.selectedItems.forEach((el, key) => {
        classrooms[key] = {
          code: el.code,
        }
      })

      const request = {
        code: this.coupon,
        classrooms: classrooms,
      } as CouponRequest

      await CouponModel.check(request)
        .then(({ data, success }) => {
          if (success) {
            this.useCoupon = true
            this.isCouponExist = true

            this.discount = 0
            this.updateSubtotal()
            this.discount = this.total - data.total
            if (data.price != '') {
              this.discountWord = 'Potongan (' + this.coupon + ')'
            } else {
              this.discountWord =
                'Potongan (' + this.coupon + ') ' + data.percentage + '%'
            }
            this.updateSubtotal()
          }
        })
        .catch(() => {
          this.useCoupon = true
          this.isCouponExist = false
          this.alertCoupon = 'Kupon ' + this.coupon + ' tidak ditemukan'
          this.discount = 0
          this.discountWord = ''
          this.updateSubtotal()
        })
    },
    async checkout() {
      if (this.selectedItems.length <= 0 || this.paymentCode == ``) {
        return
      }

      if (this.paymentCode == `paylater`) {
        let text = ``
        this.selectedItems.forEach((el, key) => {
          if (key > 0) {
            text += `, `
          }
          text += el.name
        })

        const encodedText = encodeURI(
          `Halo, saya ingin membeli kelas ${text} dengan PAYLATER`
        )
        window.location.href = `https://api.whatsapp.com/send/?phone=6285155421080&text=${encodedText}`
        return
      }

      this.process = true

      const {
        data,
        success,
      } = await TransactionModel.addTransactionWithPayment(
        this.selectedItems,
        this.paymentCode,
        this.coupon
      )

      if (success) {
        await this.getCart()
        this.$router.push(`/payment/${data.code}`)
        return
      }

      this.process = false
    },
    moneyFormat(price: number) {
      return moneyFormat(price)
    },
    selectPayment(data: any) {
      this.selectedPayment = data
      this.paymentCode = data.code
      if (this.paymentCode == 'paylater') {
        this.coupon = ``
        this.checkCoupon()
        this.updateSubtotal()
      }
    },
    openDeleteCartItem(data: any) {
      this.selectedItem = data
      this.showModalDelete = true
    },
    closeDeleteCartItem() {
      this.selectedItem = {}
      this.showModalDelete = false
    },
    async deleteCartItem() {
      this.loadingDelete = true
      const { success } = await CartModel.delete(this.selectedItem.code)
      if (success) {
        this.removeFromSelected(this.selectedItem.code)
        await this.getCart()
        this.loadingDelete = false
        this.closeDeleteCartItem()
      }
    },
    openModalReferral() {
      this.showModalReferral = true
    },
    closeModalRefferal() {
      this.showModalReferral = false
    },
    closeDetailPayment() {
      this.showDetailPayment = false
    },
    async redirectClassroom(code: string) {
      await ClassroomModel.detailPublicV2(code)
        .then(res => {
          if (res.data.landing_page.includes('https')) {
            window.location.href = res.data.landing_page
          } else {
            this.$router.push('/classroom/' + code)
          }
        })
        .catch(err => {
          this.$router.push('/classroom/' + code)
        })
    },
  },
})
</script>

<style scoped>
.route-enter-from,
.route-leave-to {
  opacity: 0;
  transform: translateX(-1rem);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.5s ease;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
